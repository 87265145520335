import { Component, Input, OnInit } from '@angular/core';
import { ZoomMeeting } from '@app/shared/interfaces/zoom';
import { Observable } from 'rxjs';

@Component({
  selector: 'lfx-meeting-header',
  templateUrl: './meeting-header.component.html',
  styleUrls: ['./meeting-header.component.scss']
})
export class MeetingHeaderComponent implements OnInit {
  @Input() public meeting: ZoomMeeting;

  public logoURL$: Observable<string>;

  public constructor() {}

  public ngOnInit(): void {}
}
