import { Component } from '@angular/core';

@Component({
  selector: 'lfx-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent {
  public constructor() {}
}
