import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '@environments/environment';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'lfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'LFX Meetings';

  public constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    datadogRum.init({
      applicationId: 'db4207a3-e597-4379-b725-a4c0cee4af47',
      clientToken: 'pub71e2a1cfff953b18517b113f941460ed',
      site: 'datadoghq.com',
      service: 'pcc-meeting-join-page',
      env: environment.datadogEnv,
      traceSampleRate: environment.datadogEnv ? 100 : 0,
      sessionSampleRate: environment.datadogEnv ? 100 : 0,
      sessionReplaySampleRate: environment.datadogEnv ? 100 : 0,
      telemetrySampleRate: environment.datadogEnv ? 100 : 0,
      trackUserInteractions: true,
      allowedTracingUrls: environment.traceOrigins,
      defaultPrivacyLevel: 'allow'
    });

    if (environment.datadogEnv) {
      datadogRum.startSessionReplayRecording();
    }

    this.authService.user$.pipe(take(1)).subscribe((user: User | undefined | null) => {
      if (user) {
        datadogRum.setUser({
          id: user['https://sso.linuxfoundation.org/claims/username'],
          email: user.email,
          name: user.name
        });
      }
    });

    this.authService.isLoading$
      .pipe(
        switchMap((isLoading) => {
          if (!isLoading) {
            return this.authService.appState$;
          }

          return [];
        })
      )
      .pipe(take(1))
      .subscribe((appState) => {
        if (appState.meetingID) {
          if (appState.password) {
            this.router.navigate(['/meeting', appState.meetingID], { queryParams: { password: appState.password } });
          } else {
            this.router.navigate(['/meeting', appState.meetingID]);
          }
        } else if (localStorage.getItem('meetingData')) {
          const meetingData = JSON.parse(localStorage.getItem('meetingData') || '{}');
          const meetingID = meetingData.meetingID;
          const password = meetingData.password;

          if (password) {
            this.router.navigate(['/meeting', meetingID], { queryParams: { password } });
          } else {
            this.router.navigate(['/meeting', meetingID]);
          }

          localStorage.removeItem('meetingData');
        } else {
          this.router.navigate(['/not-found']);
        }
      });
  }
}
