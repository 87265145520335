<div class="meeting-header">
  <div class="flex flex-col gap-3">
    <div class="flex flex-col md:flex-row gap-3 flex-wrap justify-between items-start">
      <h2>JOIN MEETING</h2>
      <img *ngIf="meeting.projectLogo" [src]="meeting.projectLogo" class="max-w-96 max-h-24 w-full h-auto">
    </div>
    <ng-container *ngIf="meeting.occurrences && meeting.occurrences.length > 0; else noOccurrences">
      <h1>{{ meeting.occurrences[0].topic }}</h1>
      <p>{{ meeting.occurrences[0].agenda }}</p>
    </ng-container>
    <ng-template #noOccurrences>
      <h1>{{ meeting.topic }}</h1>
      <p>{{ meeting.agenda }}</p>
    </ng-template>
    <div>
    </div>
  </div>
</div>
