<div *ngIf="meeting$ | async as meeting; else loading" class="meeting-container">
  <lfx-meeting-header [meeting]="meeting"></lfx-meeting-header>
  <lfx-meeting-form [meeting]="meeting" [password]="password"></lfx-meeting-form>
  <div class="flex flex-col items-center gap-3">
    <p class="text-xs font-opensans">Powered by</p>
    <img class="max-h-8 w-full"
      src="https://lf-master-project-logos-prod.s3.us-east-2.amazonaws.com/test-project-group.svg">
  </div>
</div>

<ng-template #loading>
  <div class="meeting-container">
    <!-- TODO: REPLACE -->
    <!-- <ngx-skeleton-loader count="1" [theme]="{ height: '225px' }"></ngx-skeleton-loader>
    <ngx-skeleton-loader count="1" [theme]="{ height: '375px' }"></ngx-skeleton-loader> -->
  </div>
</ng-template>
