import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@app/shared/services/user.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'lfx-service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent implements OnInit {
  private authService: AuthService = inject(AuthService);
  private router: Router = inject(Router);
  private userService: UserService = inject(UserService);

  public ngOnInit(): void {
    this.authService.handleRedirectCallback().subscribe;
    this.authService.error$.subscribe((error) => {
      console.error(error);
      this.userService.skipAuth0.set(true);
      // If there is an error, get the meetingData from local storage and redirect to the meeting join page
      const meetingData = localStorage.getItem('meetingData');
      if (meetingData) {
        const meeting = JSON.parse(meetingData);
        this.router.navigate(['/meeting/', meeting.meetingID], { queryParams: { password: meeting.password, error: 'auth0' } });
      }
    });
  }
}
