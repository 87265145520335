import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './modules/auth/auth.component';
import { JoinMeetingComponent } from './modules/join-meeting/join-meeting.component';
import { MeetingNotAuthorizedComponent } from './modules/meeting-not-authorized/meeting-not-authorized.component';
import { MeetingNotFoundComponent } from './modules/meeting-not-found/meeting-not-found.component';
import { MeetingPasswordRequiredComponent } from './modules/meeting-password-required/meeting-password-required.component';
import { ServiceUnavailableComponent } from './modules/service-unavailable/service-unavailable.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'meeting/:id',
    component: JoinMeetingComponent
  },
  {
    path: 'not-found',
    component: MeetingNotFoundComponent
  },
  {
    path: 'not-authorized',
    component: MeetingNotAuthorizedComponent
  },
  {
    path: 'password-required',
    component: MeetingPasswordRequiredComponent
  },
  {
    path: 'service-unavailable',
    component: ServiceUnavailableComponent
  },
  {
    path: 'meetings',
    loadChildren: () => import('./modules/public-meetings/public-meetings.module').then((m) => m.PublicMeetingsModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      urlUpdateStrategy: 'deferred',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
